@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap');

* {
  font-family: 'Poppins', 'Heebo', sans-serif !important;
}

body,
html {
  transition: 0.3s;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.section {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 64px;
}

.MuiTimelineOppositeContent-root {
  flex: 0.4 1 !important;
}

